import { createContext } from "react";
import { PersistedDataData } from "./index.interface";

export const PersistedDataContext = createContext<PersistedDataData>({
  cinematics: null,
  collectables: null,
  savedLocation: null,
  settings: null,
  places: null,
  quiz: null,
  dispatch: null
});
