/**
 * @author Tommy Brière
 * @copyright (c) 2021 Cube Noir
 * @fileoverview Sauvegarde des paramètres
 * */

import { WEBSITE_SITE_ROOT } from "../../utils/config";
import { isCompat } from "../../utils/interactivity";
import { useAppSelector } from "./hooks";
import { DataSlice, PersistedDataPersisted } from "./index.interface";
import { createAction } from "./utils";

/* eslint react-hooks/rules-of-hooks: "off" */

export type AccessibilityMode = "full" | "basic" | "noscript";
export interface SettingsState {
  soundEnabled: boolean;
  mode: AccessibilityMode;
  subtitleEnabled: boolean;
  show360Warning: boolean;
}

const initialState: SettingsState = {
  soundEnabled: true,
  subtitleEnabled: true,
  mode: "full",
  show360Warning: true
};

if (typeof document !== "undefined") {
  try {
    initialState.show360Warning = sessionStorage.getItem("show360Warning") !== "0";
  } catch (err) {
    console.error(err?.message);
    initialState.show360Warning = document.referrer.indexOf(WEBSITE_SITE_ROOT) !== 0;
  }
}

export const settingsSlice: DataSlice = {
  name: "settings",
  initialState,
  reducers: {
    toggleSound: createAction<SettingsState, void>("settings", (state: SettingsState) => {
      return {
        soundEnabled: !state.soundEnabled
      };
    }),
    toggleSubtitle: createAction<SettingsState, void>("settings", (state: SettingsState) => {
      return {
        subtitleEnabled: !state.subtitleEnabled
      };
    }),
    setMode: createAction<SettingsState, AccessibilityMode>(
      "settings",
      (state: SettingsState, action: AccessibilityMode) => {
        return {
          mode: action,
          show360Warning: false
        };
      }
    ),
    setShow360Warning: createAction<SettingsState, boolean>("settings", (state: SettingsState, action: boolean) => {
      try {
        sessionStorage.setItem("show360Warning", action ? "1" : "0");
      } catch (err) {
        console.error(err?.message);
      }
      return {
        show360Warning: action
      };
    })
  }
};

export const { toggleSound, setMode, toggleSubtitle, setShow360Warning } = settingsSlice.reducers;

// Other code such as selectors can use the imported `RootState` type
export const selectSettings = (state: PersistedDataPersisted) => state.settings;

/**
 * Use settings as configured in file system
 * @returns
 */
export const useSavedSettings = () => {
  return typeof document !== "undefined" ? useAppSelector(selectSettings) : initialState;
};

export const getSupportedMode = (wanted?: AccessibilityMode): AccessibilityMode => {
  if (typeof document === "undefined" || wanted === "noscript") {
    return "noscript";
  }
  if (!isCompat || wanted === "basic") {
    return "basic";
  }
  return "full";
};

/**
 * Use resolved settings
 * Modify settings to accomodate hardware limits
 */
export const useCurrentSettings = (): SettingsState => {
  const s = useSavedSettings();
  return {
    ...s,
    mode: getSupportedMode(s.mode)
  };
};
