/**
 * @author Tommy Brière
 * @copyright (c) 2021 Cube Noir
 * @fileoverview Sauvegarde des quiz collectées
 * */

import { ComplexCollectableState } from "@utils/data";
import { DataSlice, PersistedDataPersisted } from "./index.interface";
import { createAction, setLastCollected } from "./utils";

export interface QuizState extends ComplexCollectableState {
  i: string;
  g: boolean;
  a: string;
}

export interface CollectedQuizState {
  items: QuizState[];
}

const initialState: CollectedQuizState = {
  items: []
};

export const quizSlice: DataSlice = {
  name: "quiz",
  initialState,
  reducers: {
    collectQuiz: createAction<CollectedQuizState, QuizState>(
      "quiz",
      (state: Readonly<CollectedQuizState>, action: QuizState) => {
        const q = state.items.find((v) => v.i === action.i);
        if (!q) {
          setLastCollected({ id: action.i, type: "quiz" });
          return {
            items: state.items.concat([action])
          };
        }
      }
    ),
    clearQuiz: createAction<CollectedQuizState, void>("quiz", (state: CollectedQuizState) => {
      return initialState;
    })
  }
};

export const { collectQuiz, clearQuiz } = quizSlice.reducers;

// Other code such as selectors can use the imported `RootState` type
export const selectQuiz = (state: PersistedDataPersisted) => state.quiz.items.map((q) => q.i);
export const selectQuizData = (state: PersistedDataPersisted) => state.quiz.items;
