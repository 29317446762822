import { useCallback, useContext, useEffect, useMemo } from "react";
import { prefetch, useRouteData } from "react-static";
import { useLocation } from "@reach/router";

import { string } from "yup";
import { NavigationContext } from "../components/TransitionRoot/NavigationContext";
import { collectCinematic, selectCinematics } from "../components/PersistedData/cinematicsSlice";
import { collectObject, selectCollectables } from "../components/PersistedData/collectablesSlice";
import { useCurrentSettings } from "../components/PersistedData/settingsSlice";
import { collectPlace, selectPlaces } from "../components/PersistedData/placesSlice";
import { selectQuiz, selectQuizData } from "../components/PersistedData/quizSlice";
import { LocationState, setSavedLocation, useSavedLocation } from "../components/PersistedData/locationSlice";
import { MEDIA_ROOT, WEBSITE_SITE_ROOT, startingLocation } from "./config";

import type {
  LocationType,
  LocationLink,
  WithPrevState,
  Cinematic,
  Place,
  CollectableObject,
  Quiz,
  InteractPoint,
  ExtendedInteractPoint,
  ComplexCollectableState,
  ExtendedPlace
} from "./data";
import { useAppDispatch, useAppSelector } from "../components/PersistedData/hooks";

/* eslint react-hooks/rules-of-hooks: "off" */
/* eslint react-hooks/exhaustive-deps: "off" */

export const trimLastSlash = (path: string) => {
  const len = path.length;
  if (path && path !== "" && path.substring(len - 1) === "/") {
    return path.substring(0, len - 1);
  }
  return path;
};

export const trimFirstSlash = (path: string) => {
  if (path && path !== "" && path.substring(0, 1) === "/") {
    return path.substring(1);
  }
  return path;
};

export const getMediaUrl = (path: string): string => {
  if (!path) {
    return "";
  }
  const mediaRoot = MEDIA_ROOT || "https://dev-tech-hero.storage.googleapis.com/medias";
  return `${trimLastSlash(mediaRoot)}/${trimFirstSlash(path)}`;
};

export interface GetUrlFunction {
  (lang: string, type: LocationType, id?: string, sub?: string | number): string;
}
export const getGetUrl = ({ collectables, places, cinematics, quiz, urls }): GetUrlFunction => {
  const getPlace = (id) => {
    return places.find((p) => {
      return p.id === id;
    });
  };

  return (lang, type: LocationType, id?, sub?) => {
    try {
      if (type === "home") {
        return "/";
      }

      if (id) {
        switch (type) {
          case "cinematic":
            const cinematic: Cinematic = cinematics.find((c) => c.id === id);
            if (!cinematic) {
              console.error("Missing cinematic", id);
              return "/";
            }
            const p2: Place = getPlace(cinematic.next.id);
            return `/${lang}/${p2[lang].url || p2.id}/${cinematic[lang].url || cinematic.id}`;
          case "collectable":
            const collectable: CollectableObject = collectables.find((c) => c.id === id);
            if (!collectable) {
              console.error("Missing collectable", id);
              return "/";
            }
            const p3: Place = getPlace(collectable.next.id);
            return `/${lang}/${p3[lang].url || p3.id}/${collectable[lang].url || collectable.id}`;
          case "quiz":
            const q: Quiz = quiz.find((c) => c.id === id);
            if (!q) {
              console.error("Missing quiz", id);
              return "/";
            }
            const p4: Place = getPlace(q.next.id);
            if (typeof sub === "number" || typeof sub === "string") {
              return `/${lang}/${p4[lang].url || p4.id}/${q[lang].url || q.id}/${sub}`;
            }
            return `/${lang}/${p4[lang].url || p4.id}/${q[lang].url || q.id}`;

          case "place":
            const place: Place = getPlace(id);
            if (typeof sub === "number" || typeof sub === "string") {
              return `/${lang}/${place[lang].url || place.id}/${sub}`;
            }
            return `/${lang}/${place[lang].url || place.id}`;
          default:
            if (typeof sub === "number" || typeof sub === "string") {
              return `/${lang}/${type}/${id}/${sub}`;
            }
            return `/${lang}/${type}/${id}`;
        }
      }
    } catch (err) {
      console.error(err);
    }
    const loc = urls[lang];
    return `/${lang}/${loc[type]}`;
  };
};

export const useGetUrl = (): GetUrlFunction => {
  const { collectables, places, cinematics, quiz, urls } = useRouteData();
  const fn = getGetUrl({ collectables, places, cinematics, quiz, urls });
  const getUrl = useCallback(fn, [collectables, places, cinematics, quiz, urls]);
  return getUrl;
};

export const useHomeUrl = () => {
  return useUrl({ type: "home" });
};

export const useLang = () => {
  let { lang } = useRouteData();
  const l: LocationState = useSavedLocation();
  if (!lang) {
    lang = l.lang || "fr";
  }
  return lang;
};

export interface UseUrlParams {
  type: LocationType;
  id?: string;
  sub?: string | number;
}

export const preload = (url): Promise<void> => {
  // template preload is broken, so preload only templates
  return prefetch(url, { type: "data" });
};

export const useUrl = ({ type, id, sub }: UseUrlParams) => {
  const get = useGetUrl();
  const lang = useLang();
  const url = get(lang, type, id, sub);
  preload(url);
  return url;
};

export const useSavedLocationUrl = () => {
  const l: LocationState = useSavedLocation();
  return useUrl(l);
};

/**
 * Obtient des informations sur l'url précédente
 * N'attache pas de gestionnaire de touche
 */
export const usePrevUrl = (def?: UseUrlParams) => {
  const savedLocation = useSavedLocation();
  const location = useLocation();

  const state: WithPrevState = location?.state;
  const prevState = state?.prevState;
  let p: string;
  try {
    p = useUrl(prevState?.location || def || savedLocation);
  } catch (err) {
    p = useHomeUrl();
  }
  const url = useMemo(() => {
    return {
      path: p,
      state: prevState?.state
    };
  }, [p, prevState?.state]);
  return url;
};

/**
 * Retourne les informations nécessaire pour retourner à la page précédente
 * Installe un gestionnaire de touche pour gérer la touche escape
 */
export const usePrevLink = (def?: UseUrlParams) => {
  const url = usePrevUrl(def);
  const { goto } = useContext(NavigationContext);
  useEffect(() => {
    const keydown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        goto(url.path, url.state);
      }
    };

    document.addEventListener("keydown", keydown);
    return () => {
      document.removeEventListener("keydown", keydown);
    };
  }, []);
  return url;
};

/**
 * Génération d'un état utilisation pour indiquer comment naviguer à cette page
 */
export const useReturnToSelfState = (): WithPrevState => {
  const location = useLocation();
  const current = useRouteData();
  const { lang, type, id } = current;
  return useMemo(() => {
    const goBackState: WithPrevState = {
      prevState: {
        location: { lang, type, id },
        state: location ? location.state : null
      }
    };
    return goBackState;
  }, [lang, type, id, location?.state]);
};

export const useCollected = (type: LocationType): string[] => {
  if (typeof document === "undefined") {
    return [];
  }
  switch (type) {
    case "place":
      return useAppSelector(selectPlaces);
    case "cinematic":
      return useAppSelector(selectCinematics);
    case "quiz":
      return useAppSelector(selectQuiz);
    default:
      return useAppSelector(selectCollectables);
  }
};

export const useCollectedData = (type: LocationType): ComplexCollectableState[] => {
  if (typeof document === "undefined") {
    return [];
  }
  switch (type) {
    case "place":
      throw new Error("Unsupported");
      return useAppSelector(selectPlaces);
    case "cinematic":
      return useAppSelector(selectCinematics).map((s) => {
        return { i: s };
      });
    case "quiz":
      return useAppSelector(selectQuizData);
    default:
      return useAppSelector(selectCollectables).map((c) => {
        return { i: c };
      });
  }
};

export const useRecordCurrentLocation = () => {
  if (typeof document === "undefined") {
    return;
  }
  const settings = useCurrentSettings();
  const location = useLocation();
  const state: WithPrevState = location?.state;
  const current = useRouteData();
  const { lang, type, id } = current;
  const dispatch = useAppDispatch();
  const saved: LocationState = useSavedLocation();
  const collecteds = useCollected(type);
  useEffect(() => {
    if (settings.mode === "noscript") {
      return;
    }
    if (lang !== saved.lang || type !== saved.type || id !== saved.id) {
      if (lang && type && id) {
        if (collecteds.indexOf(id) >= 0) {
          if (!state?.prevState) {
            dispatch(setSavedLocation(current));
          }
        }
      }
    }
  }, [current]);
};

export const useCollect = ({ type, id }: LocationLink) => {
  const dispatch = useAppDispatch();
  const url = useUrl({ type, id });

  return useCallback(() => {
    switch (type) {
      case "place":
        dispatch(collectPlace(id));
        break;
      case "cinematic":
        dispatch(collectCinematic(id));
        break;
      case "collectable":
        dispatch(collectObject(id));
        break;
      case "quiz":
        //        dispatch(collectQuiz(id));
        // console.log("ignoring standard collect for quiz", id);
        break;
    }
    return url;
  }, [type, id]);
};

export const useCollectStartingLocation = () => {
  return useCollect(startingLocation);
};

export const isBrowser = typeof document !== "undefined";

export const iosReloadWebGLCrashFix = isBrowser ? /iPad|iPhone|iPod/.test(navigator?.userAgent) : false;

export const absUrl = (url) => {
  return url.indexOf(WEBSITE_SITE_ROOT) === 0 ? url : `${WEBSITE_SITE_ROOT}${url}`;
};

/**
 * Ajouter les données sur les points collecté dans l'objet place
 *
 * @param place
 * @returns
 */
export const useExtendedPlace = (place: Place): ExtendedPlace => {
  const object = useCollectedData("collectable");
  const cinematic = useCollectedData("cinematic");
  const collectedQuiz = useCollectedData("quiz");
  const result = useMemo(() => {
    const types = {
      collectable: object,
      cinematic,
      quiz: collectedQuiz
    };
    let nbCollected = 0;

    const points = place.interactPoints.map((point: InteractPoint): ExtendedInteractPoint => {
      const ts = types[point.type];
      const f = ts.find((i) => i.i === point.id);
      if (f) {
        nbCollected++;
      }
      return {
        ...point,
        collected: f
      };
    });

    function isAllCollected(phase) {
      for (let i = 0; i < points.length; i++) {
        const pt: ExtendedInteractPoint = points[i];
        if (pt.phase === phase && !pt.collected) {
          return false;
        }
      }
      return true;
    }

    const phase = isAllCollected(0) ? 1 : 0;

    return {
      ...place,
      nbCollected,
      phase,
      interactPoints: points
    };
  }, [object, cinematic, collectedQuiz, place]);
  return result;
};
