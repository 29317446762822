import type { LocationParts } from "./data";

export const { WEBSITE_BASE_PATH } = process.env;
export const WEBSITE_SITE_ROOT = process.env.WEBSITE_SITE_ROOT || "https://herostechno.com";
export const { MEDIA_ROOT } = process.env;

/**
 * Emplacement de départ
 */
export const startingLocation: Required<LocationParts> = {
  lang: "fr",
  type: "place",
  id: "numerique",
  sub: null
};
/**
 * Langues supportées
 */
export const siteLocales = ["fr"];

export const GTM_KEY = "G-2VKT7TW9YF";

export const { GENERATOR_ENV_NAME } = process.env;
export const { K_REVISION } = process.env;
export const TARGET_EMAIL = "herostechno@cscience.ca";
export const SAVE_PREFIX = "tech-hero.";
export const VIDEO_WITH_AUDIO = false;
export const REQUIRE_CLICK_FOR_360 = false;

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyA_GLrRGQOEMWlzVnhKoVHOAcwkOYaaTZQ",
  authDomain: "tech-hero-382219.firebaseapp.com",
  projectId: "tech-hero-382219",
  storageBucket: "tech-hero-382219.appspot.com",
  messagingSenderId: "811875500235",
  appId: "1:811875500235:web:4e2bee25eddac1ae7adb4e"
};
