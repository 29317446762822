import { InteractType } from "@utils/data";
import { PersistedDataPersisted } from "./index.interface";

export interface LastCollected {
  type: InteractType;
  id: string;
}

export function createAction<T, P>(name, fn: (state: Readonly<T>, payload: P) => Partial<T>) {
  return (payload: P) => {
    return (state: PersistedDataPersisted) => {
      const ls: T = state[name];
      const r: Partial<T> = fn(ls, payload);
      return {
        [name]: {
          ...ls,
          ...r
        }
      };
    };
  };
}

let lastCollected: LastCollected;

export function setLastCollected(lc: LastCollected): void {
  lastCollected = lc;
}

export function getLastCollected(): LastCollected {
  return lastCollected;
}
