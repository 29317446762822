/**
 * @author Tommy Brière
 * @copyright (c) 2021 Cube Noir
 * @fileoverview Sauvegarde des animations collectées
 * */

import { DataSlice, PersistedDataPersisted } from "./index.interface";
import { createAction, setLastCollected } from "./utils";

export interface CollectedCinematicsState {
  items: string[];
}

const initialState: CollectedCinematicsState = {
  items: []
};

export const cinematicsSlice: DataSlice = {
  name: "cinematics",
  initialState,
  reducers: {
    collectCinematic: createAction("cinematics", (state: CollectedCinematicsState, payload: string) => {
      if (state.items.indexOf(payload) === -1) {
        setLastCollected({ id: payload, type: "cinematic" });
        return {
          items: state.items.concat([payload])
        };
      }
    }),
    clearCinematic: createAction("cinematics", (state: CollectedCinematicsState) => {
      return initialState;
    })
  }
};

export const { collectCinematic, clearCinematic } = cinematicsSlice.reducers;

// Other code such as selectors can use the imported `RootState` type
export const selectCinematics = (state: PersistedDataPersisted) => state.cinematics.items;
