(function () {
  var DAY_DURATION = 1000 * 60 * 60 * 24;
  var CookieConsent = {};
  var FOLDER = null;
  var TITLE = "";
  var TEXT = "";
  var BTN_YES = "";
  var BTN_NO = "";
  var BTN_FERMER = "Fermer";
  var WEBSITE = "";

  var cookieState = null;
  var cookieDiv = null;

  function empty() {}

  function time() {
    return new Date().getTime();
  }

  function miniAjax(url, data) {
    var request = new XMLHttpRequest();
    var completeFn = empty;
    function transferComplete() {
      completeFn();
    }

    request.open("POST", url, true);
    request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
    request.addEventListener("load", transferComplete);
    request.send(data);

    return {
      then: function (fn) {
        completeFn = fn;
      }
    };
  }

  function loadCookieState() {
    try {
      if (typeof localStorage !== "undefined") {
        var s = localStorage.getItem("cc.cookie");
        if (s) {
          var p = JSON.parse(s);
          if (p && p.time > time() - DAY_DURATION * 90) {
            cookieState = p.state;
            CookieConsent.cookieState = cookieState;
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  function hide() {
    if (cookieDiv) {
      cookieDiv.remove();
      cookieDiv = null;
    }
  }

  function cookieButton(state) {
    gtag("consent", "default", {
      ad_storage: state,
      analytics_storage: state
    });
    hide();
    CookieConsent.cookieState = state;
    try {
      if (typeof localStorage !== "undefined") {
        localStorage.setItem(
          "cc.cookie",
          JSON.stringify({
            state: state,
            time: time()
          })
        );
      }
    } catch (err) {
      console.error(err);
    }
  }
  function recordConsent(state) {
    return miniAjax(
      "https://api.cubenoir.ca/cookie-consent/record.php",
      "cookie-state=" + state + "&website=" + WEBSITE
    );
  }
  function cookieYes(e) {
    e.preventDefault();
    recordConsent("granted").then(function () {
      cookieButton("granted");
    });
    return false;
  }
  function cookieNo(e) {
    e.preventDefault();
    recordConsent("denied");
    cookieButton("denied");
    return false;
  }

  function loadCss() {
    if (FOLDER) {
      var styleCSS = document.createElement("link");
      styleCSS.rel = "stylesheet";
      styleCSS.href = FOLDER + "/consent.css";
      document.head.insertBefore(styleCSS, document.head.childNodes[document.head.childNodes.length - 1].nextSibling);
    }
  }

  function show() {
    removeEventListener("load", show);
    loadCss();
    loadCss = empty;
    cookieDiv = document.createElement("div");
    cookieDiv.id = "cookie-consent";
    cookieDiv.innerHTML =
      '<button role="button" title=' +
      BTN_FERMER +
      " aria-label=" +
      BTN_FERMER +
      ' id="bclose-popup-policy"><svg xmlns="http://www.w3.org/2000/svg"  width="35" height="35" viewBox="0 0 21 21"><g id="close-circle-svgrepo-com" transform="translate(-1.5 -1.5)"><path id="Tracé_2842" data-name="Tracé 2842" d="M22,12A10,10,0,1,1,12,2,10,10,0,0,1,22,12Z" fill="rgba(255,255,255,0.1)"/><path id="Tracé_2842_-_Contour" data-name="Tracé 2842 - Contour" d="M12,1.5A10.5,10.5,0,1,1,1.5,12,10.512,10.512,0,0,1,12,1.5Zm0,20A9.5,9.5,0,1,0,2.5,12,9.511,9.511,0,0,0,12,21.5Z" fill="#fff"/><path id="Tracé_2843" data-name="Tracé 2843" d="M8.97,8.97a.75.75,0,0,1,1.061,0L12,10.939l1.97-1.97A.75.75,0,0,1,15.03,10.03L13.061,12l1.97,1.97A.75.75,0,0,1,13.97,15.03L12,13.061l-1.97,1.97A.75.75,0,0,1,8.97,13.97L10.939,12,8.97,10.03A.75.75,0,0,1,8.97,8.97Z" fill="#fff"/></g></svg></button>' +
      "<h3>" +
      TITLE +
      "</h3><p>" +
      TEXT +
      '</p><div class="center-buttons"><button role="button" class="choix bouton-or">' +
      BTN_YES +
      "</button><button role='button' class='choix bouton-or'>" +
      BTN_NO +
      "</button></div>";
    document.body.appendChild(cookieDiv);
    var boutons = document.querySelectorAll("#cookie-consent button.choix");
    boutons[0].addEventListener("click", cookieYes);
    boutons[1].addEventListener("click", cookieNo);
    var bclose = document.querySelector("#bclose-popup-policy");
    bclose.addEventListener("click", hide);
  }

  function init(cfg) {
    FOLDER = cfg.folder || FOLDER;
    TITLE = cfg.title || TITLE;
    TEXT = cfg.text || TEXT;
    BTN_FERMER = cfg.btnFermer || BTN_FERMER;
    BTN_YES = cfg.btnYes || BTN_YES;
    BTN_NO = cfg.btnNo || BTN_NO;
    WEBSITE = cfg.website || WEBSITE;
    recordConsent = cfg.recordConsent || recordConsent;

    loadCookieState();
    if (typeof document !== "undefined") {
      if (!cookieState) {
        hide();
        show();
      }
    }
  }

  CookieConsent.show = show;
  CookieConsent.hide = hide;
  CookieConsent.init = init;
  if (typeof window !== "undefined") {
    window.CookieConsent = CookieConsent;
  }
})();
