/**
 * @author Tommy Brière
 * @copyright (c) 2021 Cube Noir
 * @project tech-hero
 * @fileoverview Démarrage de l'intéractivité, test des capacitées
 *  Les éléments audios & vidéos nécessitent ce code
 * */

import VideoPlayer from "./pvpjslib/VideoPlayer";

export enum InteractivityState {
  DID_NOT_TRY,
  TESTING,
  FAILED,
  SUCCES
}

export const videoPlayer = new VideoPlayer();
let audioContext;
let initState: InteractivityState = InteractivityState.DID_NOT_TRY;
let THREEAudioContext;

export const getAudioContext = () => {
  return audioContext;
};

function tryStartVideo(): Promise<void> {
  // Init vidéo player
  if (videoPlayer.etat) {
    // Retry lanching video...
    videoPlayer.clicked();
  } else {
    // Create video player
    videoPlayer.init({
      loop: true,
      muted: true,
      crossorigin: true,
      click: false,
      videoUrl: "/video/blank.m4v"
    });
  }
  return new Promise((resolve, reject) => {
    videoPlayer.waitForReady(() => {
      if (videoPlayer.state === "ERROR") {
        reject();
      } else {
        resolve();
      }
    });
  });
}

function tryStartAudio(): Promise<void> {
  /* if (audioContext === undefined) {
    audioContext = THREEAudioContext.getContext();
  }
  // Init audio
  if (audioContext.state === "suspended") {
    // for chrome
    return audioContext.resume();
  }
  if (THREEAudioContext) {
    THREEAudioContext.setContext(audioContext);
  } */
  return Promise.resolve();
}

let loadingPromise;
async function realLoadFramework(): Promise<void> {
  const aframeFn = await import("aframe");
  const aframe = typeof aframeFn === "function" ? await (aframeFn as any)() : aframeFn;
  const { THREE } = aframe;
  THREEAudioContext = THREE.AudioContext;
  if (audioContext) {
    THREEAudioContext.setContext(audioContext);
  }
}

/**
 * Start framework loading or return current promise
 * @returns
 */
export function loadFramework(): Promise<void> {
  if (!loadingPromise) {
    loadingPromise = realLoadFramework();
  }
  return loadingPromise;
}

/**
 * Démarrer l'expérience sur un événement de click
 * Plusieurs événements de click peuvent être nécessaire avant de démarrer l'expérience correctement
 */
export function startInteractivity(): Promise<any> {
  if (initState !== InteractivityState.SUCCES) {
    initState = InteractivityState.TESTING;
    return Promise.all([tryStartVideo(), tryStartAudio()]).then(() => {
      initState = InteractivityState.SUCCES;
    });
  }
  return Promise.resolve();
}

function haveSufficientWebGL() {
  const canvas = document.createElement("canvas") as unknown as HTMLCanvasElement;
  // Get WebGLRenderingContext from canvas element.
  const gl =
    canvas.getContext("webgl", {
      failIfMajorPerformanceCaveat: true
    }) ||
    canvas.getContext("experimental-webgl", {
      failIfMajorPerformanceCaveat: true
    });
  // Report the result.
  if (gl && gl instanceof WebGLRenderingContext) {
    const maxTextureSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);
    return maxTextureSize >= 4096;
  }
  return false;
}

function testCompat() {
  /* @ts-ignore */
  const hageAudioContext = typeof AudioContext !== "undefined" || typeof webkitAudioContext !== "undefined";
  return typeof document !== "undefined" && hageAudioContext && haveSufficientWebGL();
}

/**
 * Effectue un test rapide de compatibilité
 * Retourne vrai si cette appareil est compatible avec les fonctionnalitées avancées
 */
export const isCompat = testCompat();

/**
 * Retourne l'état d'initialisation de l'intéractivité
 * @returns
 */
export const getInteractivityState = () => {
  return initState;
};
