import { startingLocation } from "../../utils/config";
import { LocationParts } from "../../utils/data";
import { useAppSelector } from "./hooks";
import { DataSlice, PersistedDataPersisted } from "./index.interface";
import { createAction } from "./utils";

/* eslint react-hooks/rules-of-hooks: "warn" */

export type LocationState = LocationParts;

const initialState: LocationState = {
  ...startingLocation
};

export const locationSlice: DataSlice = {
  name: "savedLocation",
  initialState,
  reducers: {
    setSavedLocation: createAction<LocationState, LocationParts>(
      "savedLocation",
      (state: LocationState, action: LocationParts) => {
        return {
          type: action.type,
          id: action.id,
          lang: action.lang,
          sub: action.sub
        };
      }
    )
  }
};

export const { setSavedLocation } = locationSlice.reducers;

// Other code such as selectors can use the imported `RootState` type
export const selectSavedLocation = (state: PersistedDataPersisted) => state.savedLocation;

export const useSavedLocation = () => {
  if (typeof document !== "undefined") {
    return useAppSelector(selectSavedLocation);
  }
  return initialState;
};
