/**
 * @author Tommy Brière
 * @copyright (c) 2021 Cube Noir
 * @fileoverview Sauvegarde des objets collectées
 * */

import { DataSlice, PersistedDataPersisted } from "./index.interface";
import { createAction, setLastCollected } from "./utils";

export interface CollectedObjectsState {
  items: string[];
}

const initialState: CollectedObjectsState = {
  items: []
};

export const collectablesSlice: DataSlice = {
  name: "collectables",
  initialState,
  reducers: {
    collectObject: createAction<CollectedObjectsState, string>(
      "collectables",
      (state: Readonly<CollectedObjectsState>, action: string) => {
        if (state.items.indexOf(action) === -1) {
          setLastCollected({ id: action, type: "collectable" });
          return {
            items: state.items.concat([action])
          };
        }
      }
    ),
    clearCollectable: createAction<CollectedObjectsState, void>("collectables", (state: CollectedObjectsState) => {
      return initialState;
    })
  }
};

export const { collectObject, clearCollectable } = collectablesSlice.reducers;

// Other code such as selectors can use the imported `RootState` type
export const selectCollectables = (state: PersistedDataPersisted) => state.collectables.items;
