/**
 * @author Tommy Brière
 * @copyright (c) 2021 Cube Noir
 * @fileoverview Show the loading screen
 * */

import React from "react";
import Fade from "react-reveal/Fade";
import Jump from "react-reveal/Jump";

const LoadingOverlay = () => {
  return (
    <div className="chargement-global">
      <div className="wrapper">
        <p className="screen-reader-only">Chargement</p>
        <img
          className="loading-img"
          src={require("../../assets/images/th-logo.svg")}
          alt="La techno dont vous êtes le héro"
        />
        <img
          className="loading-img i1"
          src={require("../../assets/images/th-logo.svg")}
          alt="La techno dont vous êtes le héro"
        />
        <img
          className="loading-img i2"
          src={require("../../assets/images/th-logo.svg")}
          alt="La techno dont vous êtes le héro"
        />
      </div>
    </div>
  );
};

export default LoadingOverlay;
